import React from "react";
import NavigationItems from "components/odl-v2/Navigation/NavigationItems";
import NavigationItem from "components/odl-v2/Navigation/NavigationItem";
import { PrimarySideNavigationItemType } from "components/PrimarySideNavigation/PrimarySideNavigation.types";
import { useTranslation } from "react-i18next";
import { PROJECTS_ENABLED } from "constants/configs";

type Props = React.ComponentProps<typeof NavigationItems> & {
  activeNavigationItemType?: PrimarySideNavigationItemType;
};

const PrimarySideNavigationItems: React.FC<Props> = ({ activeNavigationItemType, ...navigationItemsProps }) => {
  const { t } = useTranslation();

  return (
    <NavigationItems {...navigationItemsProps}>
      <NavigationItem
        icon={"i-controls-dashboard"}
        title={t(`Dashboard`)}
        to={"/"}
        isActive={activeNavigationItemType === PrimarySideNavigationItemType.Dashboard}
      />
      {!PROJECTS_ENABLED && (
        <React.Fragment>
          <NavigationItem
            icon={"i-operations-catalog-publish"}
            title={t(`Applications`)}
            to={"/applications"}
            isActive={activeNavigationItemType === PrimarySideNavigationItemType.Applications}
          />
          <NavigationItem
            icon={"i-operations-scan"}
            title={t(`Consents`)}
            to={"/consents"}
            isActive={activeNavigationItemType === PrimarySideNavigationItemType.Consents}
          />
        </React.Fragment>
      )}
      {PROJECTS_ENABLED && (
        <NavigationItem
          icon={"i-operations-catalog-publish"}
          title={t(`Projects`)}
          to={"/projects"}
          isActive={activeNavigationItemType === PrimarySideNavigationItemType.Projects}
        />
      )}

      <NavigationItem
        icon={"i-file-document-multiple-01"}
        title={t(`Libraries`)}
        to={"/libraries"}
        isActive={activeNavigationItemType === PrimarySideNavigationItemType.Libraries}
      />
      <NavigationItem
        icon={"i-commerce-building"}
        title={t(`Organisation`)}
        to={"/organisation-members"}
        isActive={activeNavigationItemType === PrimarySideNavigationItemType.OrganisationMembers}
      />
    </NavigationItems>
  );
};

export default PrimarySideNavigationItems;
