import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import { ServiceError } from "services/ServiceError";
import { IApplicationAccessRequestService } from "services/application-access-request/ApplicationAccessRequestService.types";
import { IApplicationAccessRequestEntity } from "models/ApplicationAccessRequest.model";

export class ApplicationAccessRequestService implements IApplicationAccessRequestService {
  private readonly apiClient: Cradle["apiClient"];

  constructor(args: Pick<Cradle, "apiClient">) {
    this.apiClient = args.apiClient;
  }

  public async fetchApplicationAccessRequests(): Promise<IApplicationAccessRequestEntity[]> {
    const response = await this.apiClient.protectedApi.get(`user/access-requests`);
    const accessRequestsJSON = response.data;
    if (!Array.isArray(accessRequestsJSON)) {
      throw new ServiceError();
    }

    const accessRequests = accessRequestsJSON.map((request) => {
      return this.parseApplicationRequest(request);
    });
    return accessRequests;
  }

  public async respondToApplicationAccessRequest(args: { requestGuid: string; response: string }): Promise<void> {
    const payload = {
      status: args.response,
    };
    await this.apiClient.protectedApi.patch(`user/access-requests/${args.requestGuid}`, payload);
  }

  private parseApplicationRequest(json: any): IApplicationAccessRequestEntity {
    const requestUserJson = get(json, "requestUser");
    const applicationRequest: IApplicationAccessRequestEntity = {
      requestGuid: toString(get(json, "requestGuid")),
      applicationId: toInteger(get(json, "applicationId")),
      requestUser: {
        email: toString(get(requestUserJson, "email")),
        firstName: toString(get(requestUserJson, "firstName")),
        lastName: toString(get(requestUserJson, "lastName")),
        countryCode: toString(get(requestUserJson, "userProfile.phoneCountryCode")),
        phoneNumber: toString(get(requestUserJson, "userProfile.contactNumber")),
        organisation: toString(get(requestUserJson, "organisationName")),
        address: {
          address1: toString(get(requestUserJson, "address.address1")),
          address2: toString(get(requestUserJson, "address.address2")),
          city: toString(get(requestUserJson, "address.city")),
          state: toString(get(requestUserJson, "address.state")),
          country: toString(get(requestUserJson, "address.country")),
          zipCode: toString(get(requestUserJson, "address.zipCode")),
          isManualAddress: Boolean(get(requestUserJson, "address.isManualAddress")),
          fullAddress: toString(get(requestUserJson, "address.fullAddress")),
        },
      },
    };
    return applicationRequest;
  }

  public async requestApplicationAccessRequest(args: { applicationNumber: string; authorityId: number }) {
    const payload = {
      applicationNumber: args.applicationNumber,
      authorityId: args.authorityId,
    };

    await this.apiClient.protectedApi.post("/user/access-requests", payload);
  }
}
